/* FONTS */
@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Regular.ttf');
}
@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Black.ttf');
  font-weight: 900;
}
@font-face {
  font-family: Nunito;
  src: url('./fonts/Nunito-Light.ttf');
  font-weight: 300;
}

* {
  font-family: Nunito;
}

#root {
  height: 100%;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding-bottom: 0;
  font-size: 14px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  color: #ffffff;
  overflow-x: hidden;
}

:root {
  /* THEME COLORS */
  --rsBackground: #121212;
  --rsPrimary: #ffffff;
  --rsSecondary: #cecece;
  --rsTertiary: #889097;
  --rsDisabled: #3d3d3d;
  --rsSurface: #222222;
  --rsSurfaceHover: #2e2e2e;
  --rsMajor: #37dbf6;
  --rsMinor: #8c2155;

  /* PRIMARY COLORS */
  --rsBlue: #37dbf6;
  --rsMagenta: #a7225b;
  --rsBlueRGB: 55, 219, 246;
  --rsMagentaRGB: 167, 34, 91;

  /* SECONDARY COLORS */
  --rsOrange: #f06449;
  --rsLightBlue: #aed4e6;
  --rsPeach: #f9bfa6;
  --rsYellow: #ffe99b;

  /* UTILITY COLORS */
  --rsForm: rgba(160, 194, 209, 0.3);
  --rsDropdown: #323232;

  /* FONT SIZES */
  --fontBase: 14px;
  --fontH1: 24px;
  --fontH2: 18px;
  --fontSmall: 12px;
}

/* TYPOGRAPHY */
h1 {
  font-size: 24px;
}
h2 {
  font-size: 18px;
}
p {
  font-size: 14px;
}
.small {
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 18px;
  }
  .small {
    font-size: 14px;
  }
  :root {
    --fontBase: 18px;
    --fontH1: 32px;
    --fontH2: 24px;
    --fontSmall: 14px;
  }
}

.bold {
  font-weight: bold;
}
.major {
  font-weight: bold;
  color: var(--rsMajor);
}
.secondary {
  color: var(--rsSecondary);
}
